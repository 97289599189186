import { styled } from "styled-components";
import { Swiper } from "swiper/react";

import Button from "components/Button/Button";
import { Container as GridContainer } from "style/components/Page";
import { styleHeadingL } from "style/components/Typography";
import { GUTTER, columnGap } from "style/grid";
import { MQ } from "style/mediaQueries";
import { BASE_MAX_SIZE } from "style/settings";

import "swiper/css"; /* eslint-disable-line import/no-unresolved */
import "swiper/css/navigation"; /* eslint-disable-line import/no-unresolved */
import "swiper/css/scrollbar"; /* eslint-disable-line import/no-unresolved */

const OFFSET = "0.4375rem"; // OFFSET is used to increase interactive area for scrollbar without changing the other margin/padding

export const Slider = styled(Swiper)`
    --swiper-scrollbar-bg-color: var(--line-on-neutral-default);
    --swiper-scrollbar-drag-bg-color: var(--text-on-neutral-primary);
    --swiper-scrollbar-size: 1px;
    --swiper-scrollbar-top: 0;

    && {
        padding-top: calc(1.3125rem + ${OFFSET});

        .swiper-scrollbar {
            max-width: calc(${BASE_MAX_SIZE}px - calc(${GUTTER.XL} * 2));
            left: 0;
            width: calc(100% - ${GUTTER.SM});
            margin-top: ${OFFSET};

            :hover {
                cursor: pointer;
            }

            ${MQ.FROM_M} {
                width: calc(100% - ${GUTTER.MD});
            }

            ${MQ.FROM_XL} {
                width: calc(100% - ${GUTTER.XL});
            }
        }
        .swiper-scrollbar-drag {
            position: relative;
        }

        .swiper-scrollbar-drag::before {
            content: "";
            position: absolute;
            inset: -${OFFSET} 0 -${OFFSET} 0;
        }

        ${MQ.FROM_M} {
            padding-top: calc(1.75rem + ${OFFSET});
        }

        ${MQ.FROM_XL} {
            padding-top: calc(2.1875rem + ${OFFSET});
        }
    }
`;

export const NavigationWrapper = styled.div`
    display: flex;
    grid-column: col-start 4 / span 1;
    height: 1.75rem;
    justify-content: end;

    ${MQ.FROM_M} {
        grid-column: col-start 10 / span 3;
        height: 3.5rem;
    }
`;

export const NavigationButton = styled(Button)`
    && {
        &.swiper-button-disabled,
        :disabled {
            color: var(--color-black-o40);
            cursor: not-allowed;
        }

        &.next {
            margin-left: ${OFFSET};
        }
    }
`;

export const Wrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr min(100%, ${BASE_MAX_SIZE}px) 1fr;
    width: 100%;
`;

export const Container = styled(GridContainer)`
    align-items: center;
    grid-column: 2 / span 2;
    max-width: none;
    padding-bottom: 13.125rem;
    padding-right: 0;
    padding-top: calc(2.625rem - ${OFFSET});

    .swiper-slide {
        width: calc(
            3 * var(--grid-column-size) + 2 * var(--grid-column-gutter)
        );

        ${MQ.FROM_M} {
            width: calc(
                5 * var(--grid-column-size) + 4 * var(--grid-column-gutter)
            );
        }

        ${MQ.FROM_XXL} {
            width: calc(
                5 * var(--grid-column-size) + 4 * var(--grid-column-gutter)
            );
        }
    }

    ${MQ.FROM_M} {
        padding-top: calc(3.5rem - ${OFFSET});
    }

    ${MQ.FROM_XL} {
        padding-top: calc(4.375rem - ${OFFSET});
    }
`;

export const HeadingContainer = styled(GridContainer)`
    ${columnGap};
    display: grid;
    grid-template-columns: repeat(4, [col-start] 1fr);
    padding-bottom: 0;
    padding-top: 2.1875rem;

    ${MQ.FROM_M} {
        grid-template-columns: repeat(12, [col-start] 1fr);
        padding-top: 3.5rem;
    }

    ${MQ.FROM_XL} {
        padding-top: 4.375rem;
    }
`;

export const Heading = styled.div`
    ${styleHeadingL};
    grid-column: col-start 1 / span 2;

    ${MQ.FROM_M} {
        grid-column: col-start 1 / span 6;
    }

    ${MQ.FROM_XL} {
        grid-column: col-start 1 / span 4;
    }
`;
